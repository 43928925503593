<template>
  <div class="dashboard">
    <ValueCards />
    <ValuesBox/>
    <Charts />
    <MapView class="map"/> 
    <MapFilterCards />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
  name: 'dashboard',
  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      // { title: 'Ana Sayfa', route: '/' },
    ]);
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  components: {
    Charts: () => import('@/components/dashboard/charts'),
    ValueCards: () => import('@/components/dashboard/ValueCards.vue'),
    ValuesBox: () => import ('@/components/dashboard/ValuesBox.vue'),
MapView : () =>import("@/components/dashboard/MapView"),  
  MapFilterCards : () => import("@/components/dashboard/MapFilterCards"),

  },
};
</script>
<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  gap: 2.14rem;
}
.map{
  height: 500px;
}
</style>
